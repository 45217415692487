.MountedModal_outerContainer {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: auto;
}

.MountedModal_container {  display: grid;
  grid-template-columns: 1fr .25fr;
  grid-template-rows: .1fr .1fr 1fr .1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "title closeBtn"
    "horiz horiz"
    "modalContents modalContents"
    "cancelBtn actionButtons";
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.MountedModal_title { 
  grid-area: title;
  font-size: x-large;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: left;
  align-self: center;
}
.MountedModal_horiz {
  grid-area: horiz;
}
.MountedModal_closeBtn { 
  grid-area: closeBtn;
  text-align: right;
}
.MountedModal_contents {
  grid-area: modalContents;
  text-align: center;
}
.MountedModal_cancelBtn { 
  grid-area: cancelBtn; 
  text-align: left;
  margin-left: 10px;
}
.MountedModal_actionButtons {
  grid-area: actionButtons;
  text-align: right;
  margin-right: 10px;
}