/*
*
* PasswordRequirements styles
*
*/
.PasswordRequirements_container {
  display: flex;
  flex-direction: column;
}

.PasswordRequirements_progressBarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
}

.PasswordRequirements_requirementsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PasswordRequirements_requirement {
  list-style: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
