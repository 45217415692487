/*
*
* Breadcrumbs styles
*
*/
.Breadcrumbs_container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
