.Modal_titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 25px;
}

.Modal_buttonsContainer {
  display: flex;
  justify-content: space-between !important;
  padding: 8px 30px !important;
}

.Modal_actionsButtons {
  display: flex;
}

#dialog .MuiDialog-paperWidthSm {
  max-width: 700px;
}

#dialog .MuiDialog-paper {
  padding: 10px;
}

#dialogContent.MuiDialogContent-root {
  padding: 40px 60px;
}

@media (max-width: 500px) {
  #dialogContent.MuiDialogContent-root {
    padding: 20px 10px;
  }
}