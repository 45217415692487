/*
*
* DataTable styles
*
*/

.DataTable_container {
  padding: 10px;
}

.DataTable_centeringContainer {
  display: flex;
  justify-content: center;
}

.DataTable_scrollContainer {
  overflow: auto;
  max-height: 700px;
}

.DataTable_table {
  border-collapse: collapse;
}

.DataTable_columnHeader {
  color: rgb(158, 158, 158);
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  padding: 8px 25px;
  text-align: left;
  white-space: nowrap;
}

.DataTable_columnHeaderContents {
  display: flex;
  align-items: center;
}

.DataTable_rowCell {
  font-size: 13px;
  padding: 8px 25px;
  text-align: left;
  white-space: nowrap;
}

.DataTable_toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DataTable_searchAndFilterIcons {
  display: flex;
}

.DataTable_separator {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.DataTable_filterChips {
  padding: 5px 20px;
}