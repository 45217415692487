/*
*
* Loading_Overlay styles
*
*/
.LoadingOverlay_container {
  position: relative;
  display: inline-block;
}

.LoadingOverlay_children {
  width: 100%;
  height: 100%;
}

.LoadingOverlay_children.blur {
  filter: blur(2px);
}

.LoadingOverlay_overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.35);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingOverlay_indicatorContainer {
  width: 50%;
  height: 5px;
  background-color: rgb(200, 200, 200);
  border-radius: 5px;
}

.LoadingOverlay_indicator {
  height: 100%;
  width: 50%;
  background-color: var(--primary);
  animation: loading 500ms linear 0s alternate infinite;
  border-radius: 5px;
}

@keyframes loading {

  0% {
    transform: translate(-25%, 0) scaleX(0.5);
  }

  50% {
    transform: translate(50%, 0) scaleX(1);
  }

  100% {
    transform: translate(125%, 0) scaleX(0.5);
  }
}

@media print {
  .LoadingOverlay_container {
    display: block;
  }
}