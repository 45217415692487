/*
*
* ToggleSwitch styles
*
*/
.ToggleSwitch_container {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
