/*
*
* Card Styles
*
*/
.Card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  height: 100%;
}

.Card_cardTitle {
  text-transform: uppercase;
  color: var(--text);
  font-weight: bold;
  margin-bottom: 5px;
}

.Card_children {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}