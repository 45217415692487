/*
*
* EnvHeader Styles
*
*/
.EnvHeader_container {
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    text-align: center;
    font-weight: bold;
    font-size: 1.5em;
    min-height: 48px;
    opacity: 0.65;
}

.EnvHeader_container.hide {
     display: none;
}
