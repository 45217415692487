/*
*
* CardAligned Styles
*
*/
.CardAligned_title { grid-area: title; }
.CardAligned_body { grid-area: body; }
.CardAligned_buttons { grid-area: buttons; }

.CardAligned_container {
  display: grid;
  grid-template-rows: auto fit-content(30%) auto fit-content(30%);
  grid-template-areas:
    'title'
    'body'
    'buttons';
  padding: 20px 10px;
  height: 100%;
}

.CardAligned_title {
  text-transform: uppercase;
  color: var(--text);
  font-weight: bold;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.CardAligned_body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CardAligned_buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-self: flex-end;
  min-height: 60px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .CardAligned_buttons {
    flex-direction: column;
  }
  .CardAligned_buttons button {
    margin-bottom: 5px;
  }
}